@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Geologica:wght@100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

*::-webkit-scrollbar {
  width: 3px;
  height: 0.3rem;
}

*::-webkit-scrollbar-thumb {
  background-color: #a7a7a7; /* Adjust color */
  border-radius: 10px; /* Set border radius */
}

*::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1); /* Adjust track color */
  border-radius: 10px; /* Match thumb radius for seamless look */
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}
